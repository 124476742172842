:root {
    --label-title-color: #3E3A3A;
    --label-group-title-color: #3E3A3A;
    --input-color: #3E3A3A;
}

.input {
    width: 100%;
    font-size: 14px;
    font-weight: 200;
    background: transparent;
    border: 0px solid transparent;
    color: var(--input-color);
    font-family: FiraSans-Light!important;
}

.table {
    width: 100%;
    margin: 0;
}

.table thead th {
    font-size: 12px;
    font-family: FiraSans-Regular!important;
    font-weight: 500!important;
    text-align: left;
}

.table tbody tr td {
    font-size: 12px;
    font-family: FiraSans-Light!important;
    text-align: left;
}

.iconBtn {
    font-size: 24px;
    text-align: center;
    border: 0px solid transparent;
    background: transparent;
    cursor: pointer;
    margin: auto;
}

.iconBtn2 {
    font-size: 20px;
    text-align: center;
    border: 0px solid transparent;
    background: transparent;
    cursor: pointer;
    margin: auto;
}


.label {
    text-align: left;
    font-size: 12px;
    color: var(--label-title-color);
    font-family: FiraSans-Regular!important;
}

.groupLabel {
    font-size: 16px; 
    margin-bottom: .5rem;
    color: var(--label-group-title-color);
    font-family: FiraSans-Medium!important;
}

.group {
    margin-bottom: 1.5rem;
}

.group:hover {
    background-color: #E7EAFF;
    padding: 2px; 
    border-radius: 10px;
    cursor: pointer;
}

.groupLabelAction {
    font-size: 16px; 
    margin-bottom: .5rem;
    color: var(--label-group-title-color);
    font-family: FiraSans-Medium!important;
}

.groupLabelAction:hover {
    background-color: #E7EAFF;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.deleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 2px solid darkred;
    border-radius: 20%;
    background-color: transparent;
    color: darkred;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
    margin: 0;
  }
  
  .deleteButton:hover {
    background-color: #f3c6c6;
    transform: scale(1.1);
  }

  .addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 2px solid #007bff;
    border-radius: 20%;
    background-color: transparent; 
    color: #007bff;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
    margin: 0;
  }
  
  .addButton:hover {
    background-color: #e7f3ff;
    transform: scale(1.1);
  }
  
  .addButton:focus {
    outline: none; 
  }
  
  .addButton:active {
    transform: scale(0.95);
  }

  .confirmationBox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    z-index: 1000;
  }
  
  .confirmButton {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancelButton {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }